import React from "react";
import ArticleView from "../views/ArticleView";
import ArticleDetailView from "../views/ArticleDetailView";
import CommentsView from "../views/CommentsView";
import CoreLayout from "../Layouts/CoreLayout";
import PageLayout from "../Layouts/PageLayout";
import PageView from "../views/PageView";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

class AppRouter extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Redirect exact from="/" to="artiklar" />
          <Route path="/artiklar">
            <CoreLayout>
              <Route path="/artiklar/:activeSources?" component={ArticleView} />
            </CoreLayout>
          </Route>
          <Route path="/artikkel">
            <CoreLayout>
              <Route
                path="/artikkel/:sourceId/:articleId"
                component={ArticleDetailView}
              />
            </CoreLayout>
          </Route>
          <Route path="/kommentarar">
            <CoreLayout>
              <Route component={CommentsView} />
            </CoreLayout>
          </Route>
          <Route path="/">
            <PageLayout>
              <Switch>
                <Route path="/:page*" component={PageView} />
              </Switch>
            </PageLayout>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
