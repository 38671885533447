import React from "react";
import PropTypes from "prop-types";
import NavBar from "../containers/NavBar";
import { connect } from "react-redux";
import { MapPadding } from "react-openlayers";
import { provideViewSize } from "../hocs/provideViewSize";
import "./CoreLayout.scss";

const mapDispatchToProps = {};
const mapStateToProps = state => ({});

class CoreLayout extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    viewWidth: PropTypes.number,
    viewHeight: PropTypes.number
  };

  calcMapPadding() {
    const { viewHeight, viewWidth } = this.props;
    if (viewWidth < 769) {
      return [50, 0, (6 * viewHeight) / 10, 0];
    }
    if (viewWidth < 1260) {
      return [0, 400, 0, 0];
    }
    return [0, 440, 0, 0];
  }

  render() {
    const { children } = this.props;
    return (
      <div className="core-layout--root">
        <MapPadding padding={this.calcMapPadding()} />
        <NavBar />
        <div className="core-layout--components">{children}</div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(provideViewSize(CoreLayout));
