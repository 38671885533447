import React from "react";
import PropTypes from "prop-types";
import "./SkulenMinListArticle.scss";
import LazyLoad from "react-lazyload";

export default class SkulenMinListArticle extends React.Component {
  static propTypes = {
    onArticleOpen: PropTypes.func.isRequired,
    thumbnailClassName: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
    article: PropTypes.object.isRequired,
    validTitle: PropTypes.func.isRequired,
    cleanText: PropTypes.func.isRequired,
    loadImage: PropTypes.bool
  };

  render() {
    const {
      onArticleOpen,
      thumbnailClassName,
      color,
      thumbnail,
      article,
      loadImage,
      validTitle,
      cleanText
    } = this.props;

    return (
      <div
        className={
          thumbnailClassName +
          (thumbnail ? " articleList--withThumbnail" : "") +
          " articleList--entry clearfix"
        }
        onMouseOver={() => article.olFeature.set("hover", true)}
        onMouseOut={() => article.olFeature.set("hover", false)}
        style={{ borderTop: "6px solid " + color }}
        onClick={() => onArticleOpen(article)}
      >
        <LazyLoad height="150">
          {loadImage ? (
            <img
              data-original={thumbnail}
              src={thumbnail}
              className="loaded"
              alt=""
            />
          ) : (
            <img data-original={thumbnail} alt="" />
          )}
          <div className="caption">
            {validTitle(article.properties.title) && (
              <h4>{article.properties.title}</h4>
            )}
            <div
              className="articleList--content"
              dangerouslySetInnerHTML={{
                __html: cleanText(article.properties.content)
              }}
            />
            <span className="read-more-btn">Les meir</span>
          </div>
        </LazyLoad>
      </div>
    );
  }
}
