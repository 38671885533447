export default {
  adaptiveUrl: "https://www.fylkesatlas.no/",
  // adaptiveUrl: "http://localhost/a_a3/",
  articleModuleUuid: "46f13dff-4810-450a-bb3c-19228aa6b9a4",
  // articleModuleUuid: "5a5f2a57-25f8-4b88-bde7-5fe69a7f0fc7",
  recaptchaSiteKey: "6LcSGRgTAAAAACBaIBEvrh9_LXE1fDXv7ne74uve",
  defaultLanguage: "nn",
  cms_site_id: 1,
  basename: "/",
  initExtent: [
    518518.7564631395,
    8386773.637646481,
    1306125.8959135956,
    9057584.997877188
  ]
};
