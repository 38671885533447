import React from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';

class Modal extends React.PureComponent {
  constructor (props) {
    super(props);
    this.wrapperRef = React.createRef();

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount () {
    document.body.classList.add('modal-open');
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.body.classList.remove('modal-open');
  }

  handleClickOutside (event) {
    if (event.target === this.wrapperRef.current) {
      this.props.handleClickOutside();
    }
  }

  render () {
    return (
      <div className='modal--overlay' ref={this.wrapperRef}>
        <div className='modal--content'>{this.props.children}</div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.element,
  handleClickOutside: PropTypes.func
};

export default Modal;
