import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import {
  Articles,
  articleActions,
  ensureArticleModuleConfig
} from "react-article-module";
import "./ArticleView.scss";
import SkulenMinListArticle from "../components/Article/SkulenMinListArticle";
import { mapHistoryActions } from "react-adaptive-utils";
import { olUtils, provideMapState } from "react-openlayers";

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    Object.assign({}, articleActions, mapHistoryActions),
    dispatch
  );
};

const mapStateToProps = state => ({
  lastMapCenter: state.mapHistory.lastMapCenter,
  lastMapZoom: state.mapHistory.lastZoomLevel
});

class ArticleView extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    sources: PropTypes.array.isRequired,
    match: PropTypes.object.isRequired,
    lastMapCenter: PropTypes.array,
    lastMapZoom: PropTypes.number
  };

  constructor() {
    super();

    this.onSourceSelect = this.onSourceSelect.bind(this);
    this.onArticleOpen = this.onArticleOpen.bind(this);
  }

  componentDidMount() {
    const { lastMapCenter, lastMapZoom, fitViewAndZoom } = this.props;

    if (lastMapCenter && lastMapZoom) {
      const mapCenter = olUtils.createPoint(lastMapCenter);
      fitViewAndZoom(mapCenter, lastMapZoom);
    }
  }

  componentWillUnmount() {
    const { mapCenter, mapZoom } = this.props;
    this.props.updateMapHistory(mapZoom, mapCenter);
  }

  onSourceSelect(sources) {
    const { history, setActiveSources } = this.props;
    const baseUrl = "/artiklar";
    history.push(baseUrl + "/" + sources.join("+"));
    setActiveSources(sources);
  }

  onArticleOpen(article) {
    const { history } = this.props;
    const baseUrl = "/artikkel";
    const idParts = article.id.split("_");

    history.push(
      baseUrl + "/" + idParts[0] + "/" + btoa(idParts.slice(1).join("_"))
    );

    window.scrollTo(0, 0);
  }

  get activeSources() {
    let {
      sources,
      match: {
        params: { activeSources }
      }
    } = this.props;
    activeSources = activeSources ? activeSources.split("+") : [];
    if (!activeSources.length) {
      activeSources = sources
        .filter(s => {
          return s.pressed && s.dataIndex;
        })
        .map(item => item.dataIndex);
    }

    return activeSources;
  }

  render() {
    const activeSources = this.activeSources;
    return (
      <div className="article-view--container">
        <div className="article-view--content" id="articleView-scrollPane">
          <Articles
            activeSources={activeSources}
            onSourceSelect={this.onSourceSelect}
            onArticleOpen={this.onArticleOpen}
            articleComponent={SkulenMinListArticle}
            useHoverStyle
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(provideMapState(ensureArticleModuleConfig(ArticleView))));
