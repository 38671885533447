import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import createStore from './redux/createStore';
import { CmsConfig } from 'react-adaptive-cms';
import { injectReducer } from './redux/reducers';
import SkulenMinConfig from './config/skulenmin';

import 'babel-polyfill';
import './index.scss';

CmsConfig.init(
  SkulenMinConfig.adaptiveUrl,
  SkulenMinConfig.cms_site_id
);

const initState = window.__INITIAL_STATE__;
const store = createStore(initState);

export function addReducerToStore(key, reducer) {
  injectReducer(store, { key, reducer });
}

ReactDOM.render(<App store={store} />, document.getElementById('root'));
