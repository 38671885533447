import React from 'react';
import PropTypes from 'prop-types';
import NavBar from '../containers/NavBar';
import { connect } from 'react-redux';
import { MapPadding } from 'react-openlayers';
import { provideViewSize } from '../hocs/provideViewSize';
import './PageLayout.scss';

const mapDispatchToProps = {};

const mapStateToProps = state => ({});

class PageLayout extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    viewWidth: PropTypes.number,
    viewHeight: PropTypes.number
  };

  componentDidMount () {
    const map = document.getElementById('react-openlayers-map-1');
    map.style.display = 'none';
  }

  componentWillUnmount () {
    const map = document.getElementById('react-openlayers-map-1');
    map.style.display = 'block';
  }

  calcMapPadding () {
    const { viewHeight, viewWidth } = this.props;
    if (viewWidth < 769) {
      return [50, 0, (6 * viewHeight) / 10, 0];
    }
    if (viewWidth < 1260) {
      return [0, 400, 0, 0];
    }
    return [0, 440, 0, 0];
  }

  render () {
    const { children } = this.props;
    return (
      <div className='page-layout--root'>
        <MapPadding padding={this.calcMapPadding()} />
        <NavBar />
        <div className='page-layout--components'>{children}</div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(provideViewSize(PageLayout));
