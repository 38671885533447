import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "./Carousel.scss";

class Carousel extends React.Component {
  render() {
    const { images, settings, onImageClick, galleryCarousel } = this.props;
    return (
      <div className={galleryCarousel ? "gallery--carousel" : "carousel"}>
        {images && (
          <Slider {...settings}>
            {images.map(img => (
              <div className="slider-image--container" key={img.id}>
                <img
                  src={img.url}
                  onClick={onImageClick}
                  alt={img.url}
                  className="slider-image--loaded"
                />
                {img.comment && (
                  <div className="slider-image--caption">{img.comment}</div>
                )}
              </div>
            ))}
          </Slider>
        )}
      </div>
    );
  }
}

Carousel.propTypes = {
  mainImage: PropTypes.string,
  images: PropTypes.array,
  onImageClick: PropTypes.func,
  settings: PropTypes.object,
  galleryCarousel: PropTypes.bool
};

export default Carousel;
