import React from "react";
import PropTypes from "prop-types";
import Icon from "../components/Icon";
import { Search } from "react-article-module";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Nav, Link, navActions } from "react-adaptive-cms";

import Logo from "../static/skulenmin_logo_tekst_svart.png";
import "./NavBar.scss";

const mapDispatchToProps = dispatch => {
  return bindActionCreators(Object.assign({}, navActions), dispatch);
};

const mapStateToProps = state => ({
  nav: state.nav.nav
});

export class NavBar extends React.Component {
  constructor() {
    super();
    this.state = {
      navOpen: false,
      searchOpen: false
    };
    this.onHamburgerClick = this.onHamburgerClick.bind(this);
    this.onResultSelected = this.onResultSelected.bind(this);
  }

  componentDidMount() {
    const { loadNav } = this.props;
    loadNav();
  }

  onHamburgerClick() {
    const { navOpen } = this.state;
    this.setState({ navOpen: !navOpen });
  }

  onResultSelected(result, source) {
    const { history } = this.props;
    history.push(
      "/artikkel/" +
        [
          source.dataIndex,
          btoa(
            result.id
              .split("_")
              .slice(1)
              .join("_")
          )
        ].join("/")
    );
    this.setState({ searchOpen: false });
  }

  render() {
    const { searchOpen, navOpen } = this.state;
    const { nav } = this.props;
    return (
      <div className="nav">
        <Link to="/" className="nav--logo">
          <img src={Logo} alt="Site Logo" />
        </Link>
        <div
          className={[
            "nav-bar--search",
            !searchOpen ? "nav-bar--search-hidden" : ""
          ].join(" ")}
        >
          {searchOpen && (
            <Search
              clearButtonIcon={<Icon name="cross" />}
              onResultSelected={this.onResultSelected}
            />
          )}
        </div>
        <button
          className={
            searchOpen
              ? "nav-bar--search-button nav-bar--search-active"
              : "nav-bar--search-button"
          }
          onClick={() => this.setState({ searchOpen: !searchOpen })}
        >
          <Icon name="search" />
        </button>
        <div
          className={["nav--menu", navOpen ? "nav--menu-active" : ""].join(" ")}
        >
          <input
            type="checkbox"
            className="nav--hamburger"
            onClick={this.onHamburgerClick}
          />
          <span />
          <span />
          <span />
          <Nav nav={nav} onNavClick={this.onHamburgerClick} />
        </div>
      </div>
    );
  }
}

NavBar.propTypes = {
  loadNav: PropTypes.func,
  history: PropTypes.object.isRequired,
  nav: PropTypes.array
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavBar));
