import React from "react";
import PropTypes from "prop-types";
import Carousel from "../Carousel";
import Title from "../Title";
import Icon from "../Icon";
import Modal from "../Modal";
import { connect } from "react-redux";
import { olUtils } from "react-openlayers";
import {
  mediaReducerActions,
  scopedBindActionCreators,
} from "react-adaptive-utils";
import "./SkulenMinArticle.scss";
import config from "../../config/skulenmin";
import Loading from "../Loading";

const mapDispatchToProps = (dispatch) => {
  return {
    readMedia: scopedBindActionCreators(
      mediaReducerActions.read,
      dispatch,
      "mediaReducer"
    ),
  };
};

const mapStateToProps = (state) => ({
  articleId: state.comments.articleId,
  images: state.mediaReducer.records,
  mediaLoading: state.mediaReducer.loading,
  mediaError: state.mediaReducer.error,
});

class SkulenMinArticle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      carouselPhotoFullscreen: false,
    };

    this.onModalClose = this.onModalClose.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    const { article } = this.props;

    if (article && article.globalId.startsWith("avdigitheme")) {
      this.loadMedia();
    }
  }

  componentDidUpdate(prevProps) {
    const { article } = this.props;
    if (
      ((!prevProps.article && article) ||
        prevProps.article.id !== article.id) &&
      article.globalId.startsWith("avdigitheme")
    ) {
      this.loadMedia();
    }
  }

  loadMedia() {
    const { article, readMedia } = this.props;
    readMedia(article.properties.uuid, article.sourceId);
  }

  onModalClose() {
    this.setState({ carouselPhotoFullscreen: false });
  }

  openModal() {
    this.setState({ carouselPhotoFullscreen: true });
  }

  formatContent(content) {
    return content.replace(/\n/g, "</br>");
  }

  get getGoogleCoords() {
    const { article } = this.props;
    const coord = article.olFeature.getGeometry().getCoordinates();
    const latlon = olUtils.transformCoordinate(coord, "EPSG:3857", "EPSG:4326");

    return "maps/search/?api=1&query=" + latlon[1] + "," + latlon[0];
  }

  get images() {
    const { images, article, image, aid } = this.props;

    var isLokalhistorieWiki = aid && aid.startsWith("lokalhistorie");

    if (article.globalId.startsWith("avdigitheme")) {
      return images.map((img) => {
        return {
          id: img.id,
          url:
            config.adaptiveUrl +
            "WebServices/generic/Media.asmx/Download?uuid=" +
            img.uuid,
          comment: isLokalhistorieWiki
            ? JSON.parse(img.data).beskrivelse
            : img.comment,
        };
      });
    }

    if (article.properties.images && article.properties.images.length > 0) {
      return article.properties.images.map((img, idx) => {
        return {
          id: idx,
          url: img,
        };
      });
    }

    if (image) {
      return [
        {
          id: image,
          url: image,
        },
      ];
    }
    return undefined;
  }

  render() {
    const {
      title,
      content,
      mediaLoading,
      vimeo,
      link,
      provider,
      aid,
      photographer,
      kommune,
      datering,
    } = this.props;
    const { carouselPhotoFullscreen } = this.state;
    const isFotoApi = aid && aid.startsWith("fotoapi");
    const isFotoWare = aid && aid.startsWith("fotoware");

    return (
      <div className="skulenmin--article">
        <Title title={title} />
        <h2 className="skulenmin--article-content-header">{title}</h2>
        {this.images && this.images.length > 0 && (
          <div className="carousel-container">
            {mediaLoading && <Loading />}
            {!vimeo && (
              <Carousel
                images={this.images}
                onImageClick={this.openModal}
                className="carousel"
                settings={{
                  centerMode: true,
                  centerPadding: 0,
                  dots: false,
                  infinite: true,
                  speed: 500,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  adaptiveHeight: true,
                }}
              />
            )}
          </div>
        )}

        {vimeo && (
          <div
            className="article--vimeo"
            style={{
              paddingBottom: (100 * vimeo.height) / vimeo.width + "%",
            }}
          >
            <iframe
              title={title}
              src={"https://player.vimeo.com/video/" + vimeo.video_id}
              frameBorder={0}
              allowFullScreen
            />
          </div>
        )}
        {(!isFotoApi || !isFotoWare) && (
          <div
            className="skulenmin--article-content"
            dangerouslySetInnerHTML={{ __html: this.formatContent(content) }}
          />
        )}
        {isFotoApi && (
          <div className="skulenmin--article-content">
            <div className="additionalColumn">
              <span className="additionalColumnLabel">Fotograf</span>
              <span className="additionalColumnContent">{photographer}</span>
            </div>
            <div className="additionalColumn">
              <span className="additionalColumnLabel">Kommune</span>
              <span className="additionalColumnContent">{kommune}</span>
            </div>
            <div className="additionalColumn">
              <span className="additionalColumnLabel">Datering</span>
              <span className="additionalColumnContent">{datering}</span>
            </div>
          </div>
        )}
        {isFotoWare && (
          <div className="skulenmin--article-content">
            <div className="additionalColumn">
              <span className="additionalColumnLabel">Fotograf</span>
              <span className="additionalColumnContent">{photographer}</span>
            </div>
            <div className="additionalColumn">
              <span className="additionalColumnLabel">Kommune</span>
              <span className="additionalColumnContent">{kommune}</span>
            </div>
            <div className="additionalColumn">
              <span className="additionalColumnLabel">Datering</span>
              <span className="additionalColumnContent">{datering}</span>
            </div>
          </div>
        )}
        {provider && (
          <div className="skulenmin--article-source">
            <span>Kjelde:</span>
            {provider}
          </div>
        )}
        <div className="skulenmin--article--button-bar">
          <a
            href={link}
            className="link-button share-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <Icon name="share" /> */}
            <span className="link-button-text">Opne kjelde</span>
          </a>
          <a
            href={`https://www.google.com/${this.getGoogleCoords}`}
            className="link-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* <Icon name="googlemaps" /> */}
            <span className="link-button-text">Opne i Google Maps</span>
          </a>
        </div>
        {carouselPhotoFullscreen && (
          <Modal
            onClose={this.onModalClose}
            handleClickOutside={this.onModalClose}
          >
            <div className="modal-carousel">
              <button
                className="modal-carousel--close"
                onClick={this.onModalClose}
              >
                <Icon name="cross" />
              </button>
              <Carousel
                galleryCarousel
                images={this.images}
                settings={{
                  centerMode: true,
                  centerPadding: 0,
                  dots: false,
                  infinite: true,
                  speed: 500,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

SkulenMinArticle.propTypes = {
  article: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.string,
  images: PropTypes.array,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkulenMinArticle);
