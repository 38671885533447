import { combineReducers } from "redux";
import locationReducer from "./location";
import { articleReducer, commentsReducer } from "react-article-module";
import { navReducer, pageReducer } from "react-adaptive-cms";
import { mapHistoryReducer, mediaReducer } from "react-adaptive-utils";
import config from "../config/skulenmin";

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    location: locationReducer,
    articles: articleReducer,
    comments: commentsReducer,
    nav: navReducer,
    page: pageReducer,
    mapHistory: mapHistoryReducer,
    mediaReducer: mediaReducer("mediaReducer", config.adaptiveUrl),
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
