import React, { Component } from "react";
import PropTypes from "prop-types";
import Router from "../routes";
import { Provider } from "react-redux";
import { Map, WmsLayer } from "react-openlayers";
import { TranslatorProvider } from "react-translate";
import { translations, ArticleModule } from "react-article-module";
import SkulenMinConfig from "../config/skulenmin";
import { addReducerToStore } from "../index";

class App extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired
  };

  state = {
    center: window.innerWidth > 768 ? [782700, 8741950] : [2000000, 8762353],
    zoom: window.innerWidth > 768 ? 9 : 7
  };

  onSearchInit(reducer) {
    addReducerToStore("searchSources", reducer);
  }

  render() {
    const { center } = this.state;
    const { store } = this.props;
    return (
      <Provider store={store}>
        <TranslatorProvider translations={translations.nn}>
          <Map
            baatUrl={SkulenMinConfig.adaptiveUrl}
            center={center}
            minZoom={4}
            maxZoom={18}
            extent={SkulenMinConfig.initExtent}
            track
          >
            <WmsLayer
              key="europakart"
              name="Europakart"
              id="europakart"
              layerName={[
                "Vmap0Hav",
                "Vmap0Land",
                "Land",
                "Vmap0Kystkontur",
                "Vmap0AdministrativeGrenser"
              ].join(",")}
              version="1.1.1"
              uri="https://wms.geonorge.no/skwms1/wms.europa?"
              singleTile
              zIndex={-4}
            />
            <WmsLayer
              key="norgeskart"
              name="Norgeskart"
              uri={[
                "https://gatekeeper1.geonorge.no/BaatGatekeeper/gk/gk.cache",
                "https://gatekeeper2.geonorge.no/BaatGatekeeper/gk/gk.cache",
                "https://gatekeeper3.geonorge.no/BaatGatekeeper/gk/gk.cache"
              ]}
              id="norges_grunnkart"
              layerName="norges_grunnkart"
              version="1.1.1"
              zIndex={-3}
              useBaat
            />
            <ArticleModule
              adaptiveUrl={SkulenMinConfig.adaptiveUrl}
              articleModuleUuid={SkulenMinConfig.articleModuleUuid}
              onSearchInit={this.onSearchInit}
              enableSearch
              singleTile
            >
              <Router />
            </ArticleModule>
          </Map>
        </TranslatorProvider>
      </Provider>
    );
  }
}

export default App;
