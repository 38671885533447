import React from "react";
import PropTypes from "prop-types";
import { Comments } from "react-article-module";
import "./CommentsView.scss";

class CommentsView extends React.Component {
  constructor() {
    super();

    this.openArticle = this.openArticle.bind(this);
  }

  openArticle(globalId, articleId, source) {
    const { history } = this.props;
    if (source) {
      history.push(
        "artikkel/" +
          [
            source.dataIndex,
            btoa(
              articleId
                .split("_")
                .slice(1)
                .join("_")
            ),
          ].join("/")
      );
    } else {
      let source = globalId
        .split("_")
        .slice(0)[0]
        .toLowerCase();
      switch (source) {
        case "lokalhistoriewiki":
          source = "5dmvvwku";
          break;
        case "flickr":
          source = "0482kzyo";
          break;
        case "fotoapi":
          source = "8lp162ea";
          break;
        case "fotoware":
          source = "5v220tnn";
          break;
        case "norvegiana":
          source = "no0xkhyh";
          break;
        default:
          return source;
      }
      history.push(
        "artikkel/" +
          [
            source,
            btoa(
              globalId
                .split("_")
                .slice(1)
                .join("_")
            ),
          ].join("/")
      );
    }
  }

  render() {
    return (
      <div className="comments--container">
        <div className="comments--content">
          <Comments linkToArticle openArticle={this.openArticle} />
        </div>
      </div>
    );
  }
}

CommentsView.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CommentsView;
