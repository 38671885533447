import React from "react";
import PropTypes from "prop-types";
import Title from "../components/Title";
import SkulenMinArticle from "../components/Article/SkulenMinArticle";
import Icon from "../components/Icon";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  ArticleWindow,
  ensureArticleModuleConfig,
  ArticleComments,
} from "react-article-module";
import "./ArticleDetailView.scss";

const mapStateToProps = (state) => ({
  activeSources: state.articles.activeSources,
  articles: state.articles,
});

class ArticleDetailView extends React.Component {
  constructor() {
    super();

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    const { history, activeSources } = this.props;

    history.push(
      "/artiklar/" +
        (activeSources && activeSources.length > 0
          ? activeSources.join("+")
          : "")
    );
  }

  onArticleOpen = (article) => {
    const { history } = this.props;
    const baseUrl = "/artikkel";
    const idParts = article.id.split("_");

    history.push(
      baseUrl + "/" + idParts[0] + "/" + btoa(idParts.slice(1).join("_"))
    );

    window.scrollTo(0, 0);
  };

  render() {
    const {
      match: {
        params: { sourceId, articleId },
      },
      enableArticleComments,
    } = this.props;

    const aid = sourceId + "_" + atob(articleId);

    return (
      <div className="article-detail-view--container">
        <Title title="Skulen min" />
        <ArticleWindow
          articleComponent={SkulenMinArticle}
          id={aid}
          maxZoom={16}
          hideOpenExternalBtn
        >
          <div className="article-detail-view--children">
            <button
              className="article-detail-view--close-btn btn"
              onClick={this.onClose}
            >
              <Icon name="cross" />
            </button>
            {enableArticleComments && (
              <ArticleComments id={aid} openArticle={this.onArticleOpen} />
            )}
          </div>
        </ArticleWindow>
      </div>
    );
  }
}

ArticleDetailView.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  ensureArticleModuleConfig(withRouter(ArticleDetailView))
);
