import React from 'react';
import PropTypes from 'prop-types';

export default class Title extends React.Component {
  static propTypes = {
    title: PropTypes.string
  }

  componentDidMount () {
    this.oldTitle = document.title;
    const { title } = this.props;
    if (title) {
      document.title = title;
    }
  }

  componentWillReceiveProps (props) {
    if (props.title) {
      document.title = props.title;
    }
  }

  componentWillUnmount () {
    document.title = this.oldTitle;
  }

  render () { return null; }
}
