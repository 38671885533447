import React from "react";
import PropTypes from "prop-types";
import "./Icon.scss";

const icons = {
  search: (
    <svg viewBox="0 0 32 32">
      <path d="M31.008 27.231l-7.58-6.447c-0.784-0.705-1.622-1.029-2.299-0.998 1.789-2.096 2.87-4.815 2.87-7.787 0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12c2.972 0 5.691-1.081 7.787-2.87-0.031 0.677 0.293 1.515 0.998 2.299l6.447 7.58c1.104 1.226 2.907 1.33 4.007 0.23s0.997-2.903-0.23-4.007zM12 20c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z" />
    </svg>
  ),
  pencil: (
    <svg viewBox="0 0 32 32">
      <path d="M27 0c2.761 0 5 2.239 5 5 0 1.126-0.372 2.164-1 3l-2 2-7-7 2-2c0.836-0.628 1.874-1 3-1zM2 23l-2 9 9-2 18.5-18.5-7-7-18.5 18.5zM22.362 11.362l-14 14-1.724-1.724 14-14 1.724 1.724z" />
    </svg>
  ),
  unlock: (
    <svg viewBox="0 0 32 32">
      <path d="M24 2c3.308 0 6 2.692 6 6v6h-4v-6c0-1.103-0.897-2-2-2h-4c-1.103 0-2 0.897-2 2v6h0.5c0.825 0 1.5 0.675 1.5 1.5v15c0 0.825-0.675 1.5-1.5 1.5h-17c-0.825 0-1.5-0.675-1.5-1.5v-15c0-0.825 0.675-1.5 1.5-1.5h12.5v-6c0-3.308 2.692-6 6-6h4z" />
    </svg>
  ),
  checkmark: (
    <svg viewBox="0 0 32 32">
      <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z" />
    </svg>
  ),
  userplus: (
    <svg viewBox="0 0 32 32">
      <path d="M12 23c0-4.726 2.996-8.765 7.189-10.319 0.509-1.142 0.811-2.411 0.811-3.681 0-4.971 0-9-6-9s-6 4.029-6 9c0 3.096 1.797 6.191 4 7.432v1.649c-6.784 0.555-12 3.888-12 7.918h12.416c-0.271-0.954-0.416-1.96-0.416-3z" />
      <path d="M23 14c-4.971 0-9 4.029-9 9s4.029 9 9 9c4.971 0 9-4.029 9-9s-4.029-9-9-9zM28 24h-4v4h-2v-4h-4v-2h4v-4h2v4h4v2z" />
    </svg>
  ),
  userminus: (
    <svg viewBox="0 0 32 32">
      <path d="M12 23c0-4.726 2.996-8.765 7.189-10.319 0.509-1.142 0.811-2.411 0.811-3.681 0-4.971 0-9-6-9s-6 4.029-6 9c0 3.096 1.797 6.191 4 7.432v1.649c-6.784 0.555-12 3.888-12 7.918h12.416c-0.271-0.954-0.416-1.96-0.416-3z" />
      <path d="M23 14c-4.971 0-9 4.029-9 9s4.029 9 9 9c4.971 0 9-4.029 9-9s-4.029-9-9-9zM28 24h-10v-2h10v2z" />
    </svg>
  ),
  cross: (
    <svg viewBox="0 0 16 16">
      <path d="M13.957 3.457l-1.414-1.414-4.543 4.543-4.543-4.543-1.414 1.414 4.543 4.543-4.543 4.543 1.414 1.414 4.543-4.543 4.543 4.543 1.414-1.414-4.543-4.543z" />
    </svg>
  ),
  profile: (
    <svg viewBox="0 0 1000 1000">
      <path d="M670.3,574.2c82.9-55.2,137.6-149.4,137.6-256.4c0-170-137.8-307.8-307.8-307.8c-170,0-307.8,137.8-307.8,307.8c0,107,54.7,201.2,137.6,256.4C171.5,637.4,59,786.3,53.2,961.3c0,0-7.6,33.2,55.2,28c26.9-2.2,33.7-28,33.7-28c29.8-190.7,178.5-335.8,358-335.8c179.5,0,328.2,145.1,358,335.8c0,0,17.4,26.1,33.7,28c51.3,5.9,55.2-28,55.2-28C941.3,786.3,828.8,637.4,670.3,574.2z M500.1,541.6c-123.6,0-223.8-100.2-223.8-223.8c0-123.6,100.2-223.8,223.8-223.8C623.8,93.9,724,194.2,724,317.8C724,441.4,623.8,541.6,500.1,541.6z" />
    </svg>
  ),
  share: (
    <svg viewBox="0 0 76 68.6">
      <g>
        <path
          d="M52.5,56.6l4.5-4.5v13.4H3.5V20.9h4.5V61h44.5V56.6z M48,38.8c-3,0-5.9,0.3-8.7,0.9c-2.8,0.6-5.6,1.5-8.2,2.6
     c-2.6,1.1-5.2,2.6-7.6,4.2c-2.4,1.7-4.6,3.5-6.7,5.7v-4.5c0-2.9,0.4-5.6,1.1-8.3c0.7-2.7,1.8-5.1,3.1-7.4c1.4-2.3,3-4.4,4.9-6.3
     c1.9-1.9,4-3.5,6.3-4.9c2.3-1.4,4.8-2.4,7.4-3.1c2.7-0.7,5.4-1.1,8.3-1.1V3.1l24.5,24.5L48,52.1V38.8z M51.5,20.9
     c-1.3,0-2.5,0-3.7,0c-1.1,0-2.3,0.1-3.4,0.2c-1.1,0.1-2.2,0.3-3.3,0.6c-1.1,0.3-2.3,0.7-3.5,1.2c-2,0.8-3.9,1.9-5.6,3.2
     c-1.7,1.3-3.3,2.8-4.6,4.4c-1.4,1.7-2.5,3.5-3.4,5.4c-0.9,1.9-1.6,4-2.1,6.1c3.9-2.6,8.1-4.6,12.5-5.9c4.4-1.3,9-2,13.7-2h4.5v7.1
     l13.7-13.7L52.5,13.9v7.1H51.5z"
        />
      </g>
    </svg>
  ),
  facebook: (
    <svg viewBox="0 0 32 32">
      <path d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z" />
    </svg>
  ),
  googlemaps: (
    <svg viewBox="0 0 32 32">
      <path d="M 21.5 4 C 19.664063 4 17.996094 4.765625 16.8125 6 L 7 6 C 5.355469 6 4 7.355469 4 9 L 4 25 C 4 26.644531 5.355469 28 7 28 L 23 28 C 24.644531 28 26 26.644531 26 25 L 26 15.5625 C 27.03125 14.078125 28 12.554688 28 10.5 C 28 6.921875 25.078125 4 21.5 4 Z M 21.5 6 C 23.996094 6 26 8.003906 26 10.5 C 26 12.039063 25.230469 13.207031 24.125 14.78125 C 23.242188 16.039063 22.269531 17.660156 21.5 19.65625 C 20.730469 17.660156 19.757813 16.039063 18.875 14.78125 C 17.769531 13.207031 17 12.039063 17 10.5 C 17 8.003906 19.003906 6 21.5 6 Z M 7 8 L 15.5 8 C 15.175781 8.769531 15 9.617188 15 10.5 C 15 12.015625 15.53125 13.234375 16.21875 14.375 L 6 24.5625 L 6 9 C 6 8.433594 6.433594 8 7 8 Z M 10 9 C 8.34375 9 7 10.34375 7 12 C 7 13.65625 8.34375 15 10 15 C 11.65625 15 13 13.65625 13 12 L 10 12 L 10 13 L 11.71875 13 C 11.371094 13.59375 10.738281 14 10 14 C 8.898438 14 8 13.101563 8 12 C 8 10.898438 8.898438 10 10 10 C 10.550781 10 11.042969 10.230469 11.40625 10.59375 L 12.125 9.875 C 11.582031 9.332031 10.828125 9 10 9 Z M 21.5 9 C 20.671875 9 20 9.671875 20 10.5 C 20 11.328125 20.671875 12 21.5 12 C 22.328125 12 23 11.328125 23 10.5 C 23 9.671875 22.328125 9 21.5 9 Z M 17.34375 16.0625 C 18.425781 17.605469 19.535156 19.230469 20.15625 21.625 C 20.167969 21.667969 20.203125 21.804688 20.21875 21.875 L 15.875 17.53125 Z M 24 18.6875 L 24 25 C 24 25.40625 23.777344 25.75 23.4375 25.90625 L 23.84375 25.5 L 21.3125 22.96875 C 21.378906 22.976563 21.445313 23 21.5 23 C 21.722656 23 22.039063 22.933594 22.28125 22.75 C 22.523438 22.566406 22.628906 22.347656 22.6875 22.1875 C 22.808594 21.867188 22.816406 21.722656 22.84375 21.625 C 23.136719 20.492188 23.539063 19.546875 24 18.6875 Z M 14.46875 18.96875 L 21.5 26 L 7.4375 26 Z " />
    </svg>
  ),
  load: (
    <svg viewBox="0 0 32 32">
      <path d="M12 4c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM20.485 7.515c0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.209-1.791 4-4 4s-4-1.791-4-4zM26 16c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM22.485 24.485c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM14 28c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM5.515 24.485c0 0 0 0 0 0 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0 0 0 0 0 0 1.105-0.895 2-2 2s-2-0.895-2-2zM4.515 7.515c0 0 0 0 0 0 0-1.657 1.343-3 3-3s3 1.343 3 3c0 0 0 0 0 0 0 1.657-1.343 3-3 3s-3-1.343-3-3zM1.75 16c0-1.243 1.007-2.25 2.25-2.25s2.25 1.007 2.25 2.25c0 1.243-1.007 2.25-2.25 2.25s-2.25-1.007-2.25-2.25z" />
    </svg>
  ),
  chevronRight: (
    <svg viewBox="0 0 16 16">
      <path d="M5.5 0l-2 2 6 6-6 6 2 2 8-8-8-8z" />
    </svg>
  ),
  chevronLeft: (
    <svg viewBox="0 0 16 16">
      <path d="M10.5 16l2-2-6-6 6-6-2-2-8 8 8 8z" />
    </svg>
  ),
  info: (
    <svg viewBox="0 0 16 16">
      <path d="M7 4.75c0-0.412 0.338-0.75 0.75-0.75h0.5c0.412 0 0.75 0.338 0.75 0.75v0.5c0 0.412-0.338 0.75-0.75 0.75h-0.5c-0.412 0-0.75-0.338-0.75-0.75v-0.5z" />
      <path d="M10 12h-4v-1h1v-3h-1v-1h3v4h1z" />
      <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5z" />
    </svg>
  ),
  handClick: (
    <svg viewBox="0 0 32 32">
      <path d="M13 0v4h1v-4h-1zM19.403 2.578l-3.109 2.516 0.628 0.778 3.109-2.519c0 0-0.628-0.775-0.628-0.775zM21.406 9.313l-3.897-0.9-0.225 0.975 3.897 0.9 0.225-0.975zM5.816 10.287l3.897-0.9-0.225-0.975-3.897 0.9 0.225 0.975zM6.969 3.353l3.109 2.519 0.628-0.778-3.109-2.519-0.628 0.778zM19.497 31c4.144 0 7.5-3.584 7.5-7.5 0 0 0 2.313 0 0v-5.997c0-0.828-0.666-1.503-1.5-1.503-0.828 0-1.5 0.672-1.5 1.503v0.497h-1v-2.494c0-0.831-0.666-1.506-1.5-1.506-0.828 0-1.5 0.672-1.5 1.506v1.494h-1v-2.494c0-0.831-0.666-1.506-1.5-1.506-0.828 0-1.5 0.672-1.5 1.506v3.494h-1v-10.494c0-0.831-0.666-1.506-1.5-1.506-0.828 0-1.5 0.666-1.5 1.506v11.294c-2.059-2.2-4.769-4.622-5.878-3.503-1.087 1.094 1.716 4.106 5.625 10.688 1.766 2.966 3.997 5.016 7.753 5.016v0zM28 23.5c0 4.694-3.806 8.5-8.5 8.5-3.113 0-6.397-1.453-8.659-5.572-3.263-5.947-7.438-9.844-5.45-11.831 1.422-1.422 3.653-0.056 5.609 1.772v0-8.859c0-1.394 1.119-2.509 2.5-2.509 1.391 0 2.5 1.122 2.5 2.509v4.991c0.419-0.313 0.938-0.497 1.5-0.497 1.081 0 1.994 0.678 2.347 1.628 0.441-0.391 1.019-0.628 1.653-0.628 1.391 0 2.5 1.119 2.5 2.497v0.012c0.419-0.313 0.938-0.497 1.5-0.497 1.391 0 2.5 1.119 2.5 2.5v5.984z" />
    </svg>
  )
};

const Icon = props => {
  const { name } = props;
  return <span className="icon">{icons[name]}</span>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Icon;
